<template>
  <div class="journey-editor">
    <el-row :gutter="20">
      <el-col :span="editingNode ? 16 : 24">
        <div style="text-align: right; margin-bottom: 10px">
          <el-dropdown @command="addNode">
            <el-button type="primary" size="small">
              <i class="el-icon-plus"></i> Add Step
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Decision">Decision</el-dropdown-item>
              <el-dropdown-item command="Message">Message</el-dropdown-item>
              <!-- <el-dropdown-item command="Trigger">Trigger</el-dropdown-item> -->
              <el-dropdown-item command="Update">Update</el-dropdown-item>
              <!-- <el-dropdown-item command="Alert">Alert</el-dropdown-item> -->
              <el-dropdown-item command="Delay">Delay</el-dropdown-item>
              <el-dropdown-item command="Merge">Merge</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <VueDiagramEditor
          ref="diagram"
          :nodeDeletable="nodeDeletable"
          :node-color="nodeColor"
          @select-node="onSelectNode"
          @updated-node="onUpdateNode"
          @deleted-node="onDeleteNode"
          @created-link="onCreateLink"
          @deleted-link="onDeleteLink"
          style="border: 1px solid grey"
        >
          <template slot="node" slot-scope="{ node }">
            <div
              v-if="nodes[node.id].type === 'Start'"
              style="text-align: center; height: 100%; font-size: 20px"
            >
              <i class="el-icon-caret-right"></i>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'End'"
              style="text-align: center; height: 100%; font-size: 20px"
            >
              <i class="el-icon-error"></i>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Merge'"
              style="text-align: center; height: 100%; font-size: 20px"
            >
              <i class="el-icon-caret-right"></i>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Alert'"
              style="text-align: center; height: 100%"
            >
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="danger"
                v-if="nodes[node.id].alert_priority === 'High'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="warning"
                v-if="nodes[node.id].alert_priority === 'Normal'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="info"
                v-if="nodes[node.id].alert_priority === 'Low'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <div></div>
            </div>
            <div v-else style="text-align: center; height: 100%">
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="success"
                v-if="nodes[node.id].type === 'Message'"
              >
                <i class="el-icon-tickets"></i>
                {{ nodes[node.id].title.substring(0, 15) }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="warning"
                v-else-if="nodes[node.id].type === 'Decision' || nodes[node.id].type === 'Update'"
              >
                <i class="el-icon-tickets"></i>
                {{ nodes[node.id].title.substring(0, 15) }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="info"
                v-else-if="nodes[node.id].type === 'Trigger' || nodes[node.id].type === 'Delay'"
              >
                <i class="el-icon-tickets"></i>
                {{ nodes[node.id].title.substring(0, 15) }}
              </el-tag>
            </div>
          </template>
        </VueDiagramEditor>
      </el-col>
      <el-col :span="8" v-if="editingNode">
        <el-card class="journey-card" style="min-height: 550px">
          <div slot="header" class="clearfix">
            <h3>{{ nodes[currentNodeId].title }}</h3>
          </div>

          <div
            class="journey-body"
            style="height: 350px; overflow-y: scroll; margin-bottom: 20px; padding-right: 15px"
          >
            <label class="journey-label">Title</label>
            <el-input placeholder="Title" v-model="currentNode.title"></el-input>
            <br /><br />
            <div v-if="currentNode.type === 'Decision'">
              <div>
                <label class="journey-label">Decision Conditions</label>
                <div
                  v-for="(item, index) in transitions"
                  :key="'tran' + index"
                  style="padding: 10px; border: solid 1px lightblue; margin-bottom: 10px"
                >
                  <label class="journey-label">Title</label>
                  <el-input placeholder="Title" v-model="item.title"></el-input>
                  <br /><br />
                  <el-row v-for="(itm, idx) in item.rules" :key="'rule' + index + idx">
                    <el-col :span="2">
                      <strong>#{{ idx + 1 }}</strong>
                    </el-col>
                    <el-col :span="20">
                      <el-row>
                        <el-col :span="24">
                          <el-select
                            v-model="itm.variable"
                            filterable
                            allow-create
                            placeholder="Select"
                            style="width: 100%"
                          >
                            <el-option-group label="Patient Data">
                              <el-option
                                v-for="opt in patientFields"
                                :key="opt.value"
                                :label="opt.label"
                                :value="opt.value"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-col>
                      </el-row>
                      <el-row type="flex" justify="center">
                        <el-col :span="12">
                          <el-select
                            v-model="itm.operation"
                            placeholder="Select"
                            style="width: 100%"
                          >
                            <el-option
                              v-for="opt in operationOpt"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-select>
                        </el-col>
                        <!-- <el-col :span="12">
                          <el-select v-model="itm.type" placeholder="Select" style="width: 100%">
                            <el-option
                              v-for="opt in valueType"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-select>
                        </el-col> -->
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <!-- <el-select
                            v-model="itm.value"
                            filterable
                            allow-create
                            placeholder="Select"
                            style="width: 100%"
                            v-if="itm.type === 'variable'"
                          >
                            <el-option-group label="Patient Data">
                              <el-option
                                v-for="opt in patientFields"
                                :key="opt.value"
                                :label="opt.label"
                                :value="opt.value"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                          <div style="text-align: center" v-else-if="itm.type === 'boolean'">
                            <el-switch v-model="itm.value"></el-switch>
                            <label v-if="itm.value"> TRUE </label>
                            <label v-else> FALSE </label>
                          </div>
                          <el-input placeholder="Value" v-model="itm.value" v-else></el-input> -->
                          <el-input placeholder="Value" v-model="itm.value"></el-input>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="2">
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        size="small"
                        @click="deleteRule(index, idx)"
                      ></el-button>
                    </el-col>
                  </el-row>
                  <el-button type="text" @click="addRules(index)"> + add condition </el-button>
                </div>
                <el-button type="text" @click="addTransition()"> + add decision </el-button>
              </div>
            </div>
            <div v-else-if="currentNode.type === 'Message'">
              <label class="journey-label">Mesage Template</label>
              <el-time-select
                v-model="currentNode.messageTime"
                size="small"
                placeholder="Select time"
                :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"
                style="width: 100%"
              >
              </el-time-select>
              <br /><br />
              <label class="journey-label">Mesage Template</label>
              <el-select
                v-model="currentNode.whatsapp_template"
                placeholder="Select"
                style="width: 100%"
                @change="handleWATemplateChange()"
              >
                <el-option
                  v-for="temp in whatsappTemplates"
                  :key="'watmp' + temp.id"
                  :value="temp.id"
                  :label="temp.name + '(' + temp.language + ')'"
                ></el-option>
              </el-select>
              <br /><br />
              <div v-if="whatsappParams.headerText">
                <div class="automation-input-label">Header</div>
                <p>{{ whatsappParams.headerText }}</p>
              </div>

              <div v-if="whatsappParams.bodyText">
                <div class="automation-input-label">Body</div>
                <p>{{ whatsappParams.bodyText }}</p>
              </div>

              <div v-if="whatsappParams.allParameters">
                <el-row
                  v-for="(param, idx) in whatsappParams.allParameters"
                  :key="'param' + index + idx"
                >
                  <el-col :span="8">
                    <div class="automation-input-label">{{ param.label }}</div>
                  </el-col>
                  <el-col :span="6">
                    <el-select v-model="param.type" size="mini" placeholder="Select">
                      <el-option
                        v-for="opt in messageParameterType"
                        :key="'type' + opt.value"
                        :value="opt.value"
                      >
                        {{ opt.label }}
                      </el-option>
                      <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-select>
                  </el-col>
                  <el-col :span="10">
                    <el-input
                      v-model="param.value"
                      size="mini"
                      v-if="param.type === AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT"
                    ></el-input>
                    <el-select v-model="param.value" size="mini" placeholder="Select" v-else>
                      <el-option
                        v-for="opt in patientFields"
                        :key="'type' + opt.value"
                        :value="opt.value"
                      >
                        {{ opt.label }}
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
              <el-divider></el-divider>
            </div>
            <div v-else-if="currentNode.type === 'Trigger'">
              <label class="journey-label">Trigger Condition</label>
            </div>
            <div v-else-if="currentNode.type === 'Update'">
              <el-row v-for="(itm, index) in calculations" :key="'cal' + index">
                <el-col :span="2">
                  <strong>#{{ index + 1 }}</strong>
                </el-col>
                <el-col :span="20">
                  <el-row>
                    <el-col :span="24">
                      <el-select
                        v-model="itm.variable"
                        filterable
                        allow-create
                        placeholder="Select"
                        style="width: 100%"
                      >
                        <el-option-group label="Patient Data">
                          <el-option
                            v-for="opt in patientFields"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-col>
                    <!-- <el-col :span="12">
                      <el-select v-model="itm.type" placeholder="Select" style="width: 100%">
                        <el-option
                          v-for="opt in valueType"
                          :key="opt.value"
                          :label="opt.label"
                          :value="opt.value"
                        >
                        </el-option>
                      </el-select>
                    </el-col> -->
                    <el-col :span="12">
                      <!-- <el-select
                        v-model="itm.value"
                        filterable
                        allow-create
                        placeholder="Select"
                        style="width: 100%"
                        v-if="itm.type === 'variable'"
                      >
                        <el-option-group label="Patient Data">
                          <el-option
                            v-for="opt in patientFields"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                      <div style="text-align: center" v-else-if="itm.type === 'boolean'">
                        <el-switch v-model="itm.value"></el-switch>
                        <label v-if="itm.value"> TRUE </label>
                        <label v-else> FALSE </label>
                      </div>
                      <el-input placeholder="Value" v-model="itm.value" v-else></el-input> -->
                      <el-input placeholder="Value" v-model="itm.value"></el-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="2">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    size="small"
                    @click="deleteCalculations(index)"
                  ></el-button>
                </el-col>
              </el-row>
              <div>
                <el-button type="text" @click="addCalculations"> + add new update </el-button>
              </div>
            </div>
            <div v-else-if="currentNode.type === 'Alert'">
              <div>
                <label class="journey-label">Alert Priority</label>
                <el-select
                  v-model="currentNode.alert_priority"
                  placeholder="Select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in priorityOpt"
                    :key="'prio-' + item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <br /><br />
              </div>
              <div>
                <label class="journey-label">Alert Type</label>
                <el-input placeholder="Title" v-model="currentNode.alert_type"></el-input>
                <br /><br />
              </div>
            </div>
            <div v-else-if="currentNode.type === 'Delay'">
              <label class="journey-label">Delay</label>
              <el-row>
                <el-col :span="12">
                  <el-input
                    placeholder="Delay"
                    v-model="currentNode.delay"
                    type="number"
                    min="0"
                  ></el-input>
                </el-col>
                <el-col :span="12">
                  <el-select v-model="currentNode.delayType" placeholder="Select">
                    <el-option
                      v-for="opt in delayType"
                      :key="'type' + opt.value"
                      :value="opt.value"
                    >
                      {{ opt.label }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>

              <br /><br />
            </div>
          </div>
          <div style="text-align: right; margin-top: 10px">
            <el-button @click="editingNode = false" size="small">Finish</el-button>
            <el-button @click="deleteNode" type="danger" size="small">Delete</el-button>
            <el-button @click="updateNode" type="primary" size="small">Save</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueDiagramEditor from "vue-diagram-editor";
import "vue-diagram-editor/dist/vue-diagram-editor.css";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import gql from "graphql-tag";

import { rest } from "@/store/api";
import {
  AUTOMATION_ATTRIBUTE_OPERATOR,
  AUTOMATION_MESSAGE_PARAMETER_TYPE,
  AUTOMATION_SCHEDULE_TYPE,
  AUTOMATION_CHANNEL_NAME,
  AUTOMATION_MESSAGE_CONTENT_TYPE,
  AUTOMATION_UPDATE_METHOD,
  AUTOMATION_OPERATION,
} from "@/store/modules/outreach/types";
import { getOutReachVariableExcludeField } from "@/helperMethods/outreach";

export default {
  components: {
    VueDiagramEditor,
  },
  props: {
    journey: {
      default() {
        return {
          id: 0,
          title: "",
          channel: "",
          settings: {},
          nodes: {},
          links: {},
        };
      },
    },
  },
  data() {
    return {
      clientTimezone: moment.tz.guess(),
      AUTOMATION_MESSAGE_PARAMETER_TYPE,
      activeTab: "actions",
      editingNode: false,
      currentNode: null,
      currentNodeId: "",
      automationList: [],
      transitions: [],
      calculations: [],
      beforeAction: [],
      afterAction: [],
      whatsappParams: {
        bodyText: "",
      },
      formList: [],
      nodes: {},
      links: {},
      actionOpt: [
        {
          value: "fill_form",
          label: "Fill Form",
        },
        {
          value: "send_message",
          label: "Send Message",
        },
        {
          value: "calculations",
          label: "Calculations",
        },
      ],
      messageOpt: [
        {
          value: "whatsapp_template",
          label: "WhatsApp Template",
        },
        {
          value: "content_node",
          label: "Content Node",
        },
        {
          value: "text_message",
          label: "Text Message",
        },
      ],
      operationOpt: [
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
          label: "=",
          comparison: "is",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.NOT_EQUAL,
          label: "!=",
          comparison: "isNot",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.LESS_THAN,
          label: "<",
          comparison: "lessThan",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.GREATER_THAN,
          label: ">",
          comparison: "greaterThan",
        },
      ],
      priorityOpt: ["Low", "Normal", "High"],
      whenOpt: ["Immediately", "Later"],
      loading: false,
      forms: [],
      totalForms: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().hour(0).minute(0).second(0);
        },
      },
      appointmentApp: [
        {
          value: "calendly",
          label: "Calendly",
        },
        {
          value: "google",
          label: "Google Calendar",
        },
      ],
      vitalType: [
        {
          value: "blood_pressure",
          label: "Blood Pressure",
        },
        {
          value: "blood_sugar",
          label: "Blood Sugar",
        },
      ],
      valueType: [
        {
          value: "text",
          label: "Text",
        },
        {
          value: "boolean",
          label: "True/False",
        },
        {
          value: "variable",
          label: "Variable",
        },
      ],
      delayType: [
        {
          value: "days",
          label: "days",
        },
        {
          value: "weeks",
          label: "weeks",
        },
        {
          value: "months",
          label: "months",
        },
        {
          value: "years",
          label: "years",
        },
      ],
      messageParameterType: [
        {
          value: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
          label: "Text",
        },
        {
          value: AUTOMATION_MESSAGE_PARAMETER_TYPE.VARIABLE,
          label: "Variable",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["contactAttributes"]),
    patientFields() {
      const data = getOutReachVariableExcludeField(undefined, this.contactAttributes);
      const list = _.filter(data, function (item) {
        return item.value !== "tags" && item.value !== "stage";
      });
      return list;
    },
    messageParamaterVariables() {
      return getOutReachVariableExcludeField(undefined, this.contactAttributes);
    },
    contentNodes() {
      const data = this.$store.state.nodes.content || [];
      const items = _.map(data, (item) => {
        return item;
      });
      return items;
    },
    whatsappTemplates() {
      var templates = this.$store.state.whatsapp.templates || [];

      var items = _.filter(templates, function (item) {
        return item.status === "APPROVED";
      });

      return items;
    },
    transationVar() {
      const before = _.map(this.beforeAction, "variable");
      const after = _.map(this.afterAction, "variable");

      return _.union(before, after);
    },
  },
  watch: {
    "$refs.diagram.selectedNode"(val) {
      if (!val) {
        this.currentNode = null;
      }
    },
  },
  mounted() {
    this.fetchForms();
    this.fetchWhatsappTemplates();
    this.findAllAutomation();
    setTimeout(() => {
      this.init();
    }, 100);
  },
  methods: {
    vitalLabel(type) {
      const item = _.find(this.vitalType, {
        value: type,
      });
      if (item) {
        return item.label;
      } else {
        return "";
      }
    },
    init() {
      if (this.journey.nodes && Object.keys(this.journey.nodes).length > 0) {
        this.nodes = this.journey.nodes;
        this.links = this.journey.links || {};

        this.$refs.diagram.setModel({
          nodes: this.nodes,
          links: this.links,
        });
      } else {
        const start = {
          id: uuid(),
          title: "Start",
          size: {
            width: 75,
            height: 50,
          },
          coordinates: {
            x: 10,
            y: 100,
          },
          portsOut: {
            default: "",
          },
        };
        this.$refs.diagram.addNode(start);
        start.type = "Start";
        start.action = "default";
        this.nodes[start.id] = start;

        const end = {
          id: uuid(),
          title: "End",
          size: {
            width: 75,
            height: 50,
          },
          coordinates: {
            x: 400,
            y: 100,
          },
          portsIn: {
            default: "",
          },
        };
        this.$refs.diagram.addNode(end);
        end.type = "End";
        end.action = "default";
        this.nodes[end.id] = end;
      }

      setTimeout(() => {
        this.styleAllNode();
      }, 100);
    },
    async fetchForms(page = 1) {
      const response = await rest("get", "forms/v1");
      this.forms = response.data || [];
    },
    async fetchWhatsappTemplates() {
      if (this.journey.settings?.wa_endpoint) {
        await this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: {
            wa_endpoint: this.journey.settings.wa_endpoint,
          },
        });
      }
    },
    async findAllAutomation() {
      this.loading = true;
      try {
        let response = await this.$apollo.mutate({
          mutation: gql`
            mutation {
              outreachAutomationAPI {
                findAll {
                  id
                  name
                  context
                  enabled
                  channels
                  messages
                  contactTags
                  contactStages
                  contactList
                  contactAttributes
                  contactOperations
                  stateVariables
                  scheduleType
                  scheduleCron
                  startDate
                  endDate
                  sentTime
                  updates
                  createdAt
                  updatedAt
                }
              }
            }
          `,
        });

        this.automationList = _.get(response, "data.outreachAutomationAPI.findAll", []);
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    nodeColor(node) {
      const item = this.nodes[node.id];

      if (item.type === "Message") {
        return "#67C23A";
      } else if (item.type === "Trigger") {
        return "#409EFF";
      } else if (item.type === "Alert") {
        return "#F56C6C";
      } else if (item.type === "Decision") {
        return "#E6A23C";
      } else if (item.type === "Delay") {
        return "#409EFF";
      } else if (item.type === "Update") {
        return "#E6A23C";
      } else {
        return "#000";
      }
    },
    nodeDeletable() {
      return false;
    },
    addNode(type) {
      const node = {
        id: uuid(),
        title: type,
        size: {
          width: 150,
          height: 75,
        },
        portsIn: {
          default: "",
        },
        portsOut: {
          default0: "",
        },
      };

      if (type === "Merge") {
        node.size = {
          width: 75,
          height: 50,
        };
      }
      this.$refs.diagram.addNode(node);
      node.type = type;

      if (type === "Message") {
        node.automationId = uuid();
        const automation = this.newAutomation(node.automationId);
        this.saveAutomation(automation);
        this.findAllAutomation();
      }

      if (type === "Milestone") {
        node.action = "fill_form";
      } else if (type === "Notification") {
        node.action = "send_message";
      } else if (type === "Alert") {
        node.alert_priority = "Normal";
      } else {
        node.action = "default";
      }

      this.nodes[node.id] = node;
      setTimeout(() => {
        this.styleNode(node);
      }, 100);
      this.onSave();
    },
    updateNode() {
      this.$refs.diagram.updateNode({
        id: this.currentNodeId,
        name: "title",
        value: this.currentNode.title,
      });

      let ports = {
        default0: "",
      };
      if (this.transitions.length > 0 && this.currentNode.type === "Decision") {
        for (let index = 0; index < this.transitions.length; index++) {
          const element = this.transitions[index];
          ports["default" + index] = element.title;
        }

        const size = {
          with: 150,
          height: 50 + 25 * this.transitions.length,
        };
        this.$refs.diagram.updateNode({
          id: this.currentNodeId,
          name: "size",
          value: size,
        });
        this.currentNode.size = size;
      }

      this.$refs.diagram.updateNode({
        id: this.currentNodeId,
        name: "portsOut",
        value: ports,
      });
      this.currentNode.portsOut = ports;

      this.nodes[this.currentNodeId] = this.currentNode;
      this.nodes[this.currentNodeId].transitions = this.transitions;
      this.nodes[this.currentNodeId].calculations = this.calculations;
      this.nodes[this.currentNodeId].beforeAction = this.beforeAction;
      this.nodes[this.currentNodeId].afterAction = this.afterAction;
      this.nodes[this.currentNodeId].whatsappParams = this.whatsappParams;
      this.nodes[this.currentNodeId].forms = this.formList;
      this.nodes[this.currentNodeId].form = null;
      if (this.currentNode.form_id) {
        const form = _.find(this.forms, {
          id: this.currentNode.form_id,
        });
        if (form) {
          this.nodes[this.currentNodeId].form = form;
        }
      }

      if (this.currentNode.type === "Message") {
        if (!this.currentNode.automationId) {
          this.currentNode.automationId = uuid();
        }
        let automation = _.find(this.automationList, ["id", this.currentNode.automationId]);
        if (!automation) {
          automation = this.newAutomation(this.currentNode.automationId);
        }

        automation.name = `ecare_${this.journey.id}_${this.currentNode.title}`;
        automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.RECURRING;
        automation.startDate = automation.startDate
          ? moment(automation.startDate).tz(this.clientTimezone)
          : moment().tz(this.clientTimezone);
        automation.endDate = null;

        const messageTime = this.currentNode.messageTime.split(":");

        automation.startDate.hours(parseInt(messageTime[0]));
        automation.startDate.minutes(parseInt(messageTime[1]));

        var cronMinute = String(automation.startDate.minute());
        var cronHour = String(automation.startDate.hour());

        automation.scheduleCron = [[cronMinute, cronHour, "*", "*", "*"]];

        automation.schedule = {
          scheduleType: automation.scheduleType,
          startDate: automation.startDate,
          endDate: automation.endDate,
          repeatType: "Daily",
          repeatDates: "",
          repeatTime: 1,
        };

        let parameters = _.reduce(
          this.whatsappParams.allParameters,
          function (result, params) {
            result[params.key] = params;
            return result;
          },
          {}
        );

        automation.messages = [
          {
            contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
            contentId: this.currentNode.whatsapp_template,
            contentName: this.whatsappParams.content.name,
            contentParameters: parameters,
            contentBody: this.whatsappParams.bodyText,
          },
        ];

        const contactAttributes = _.filter(automation.contactAttributes, function (item) {
          return item.attribute !== "project_id";
        });

        contactAttributes.push({
          attribute: "project_id",
          operator: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
          comparison: "is",
          value: this.journey.id,
        });

        automation.contactAttributes = contactAttributes;

        this.saveAutomation(automation);
        this.findAllAutomation();
      } else if (this.currentNode.type === "Delay") {
        let automation = _.find(this.automationList, ["id", this.currentNode.automationId]);
        if (automation) {
          const contactAttributes = _.filter(automation.contactAttributes, function (item) {
            return item.attribute !== "last_journey_update";
          });
          contactAttributes.push({
            attribute: "last_journey_update",
            operator: AUTOMATION_ATTRIBUTE_OPERATOR.LESS_THAN,
            comparison: "lessThan",
            value: `NOW-${this.currentNode.delay}_${this.currentNode.delayType}`,
          });
          contactAttributes.push({
            attribute: "last_journey_update",
            operator: AUTOMATION_ATTRIBUTE_OPERATOR.GREATER_THAN,
            comparison: "greaterThan",
            value: `NOW-${parseInt(this.currentNode.delay) + 1}_${this.currentNode.delayType}`,
          });

          automation.contactAttributes = contactAttributes;
          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.currentNode.automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      } else if (this.currentNode.type === "Decision") {
        _.each(this.transitions, (transition) => {
          let automation = _.find(this.automationList, ["id", transition.automationId]);
          if (automation) {
            const contactAttributes = _.filter(automation.contactAttributes, function (item) {
              return item.attribute === "last_journey_update" || item.attribute === "project_id";
            });
            _.each(transition.rules, (rule) => {
              const comparison = _.find(this.operationOpt, ["value", rule.operation]);
              contactAttributes.push({
                attribute: rule.variable,
                operator: rule.operation,
                comparison: comparison.comparison,
                value: rule.value,
              });
            });
            automation.contactAttributes = contactAttributes;
            this.saveAutomation(automation);
            const automationIndex = _.findIndex(this.automationList, [
              "id",
              this.currentNode.automationId,
            ]);
            this.automationList[automationIndex] = automation;
          }
        });
      }
      this.onSave();
    },
    deleteNode() {
      this.$confirm("Delete this node?", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.checkAutomation();
          this.$refs.diagram.deleteNode(this.currentNodeId);
          this.currentNode = null;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    checkAutomation() {
      if (this.currentNode.type === "Update") {
        let automation = _.find(this.automationList, ["id", this.currentNode.automationId]);
        if (automation) {
          automation.updates = [
            {
              failed: false,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
              property: AUTOMATION_OPERATION.ATTRIBUTES,
              updateItem: { last_journey_update: "NOW" },
              updateTo: [],
            },
          ];
          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.currentNode.automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      } else if (this.currentNode.type === "Delay") {
        let automation = _.find(this.automationList, ["id", this.currentNode.automationId]);
        if (automation) {
          const contactAttributes = _.filter(automation.contactAttributes, function (item) {
            return item.attribute !== "last_journey_update";
          });
          automation.contactAttributes = contactAttributes;
          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.currentNode.automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      } else if (this.currentNode.type === "Decision") {
        _.each(this.transitions, (transition) => {
          let automation = _.find(this.automationList, ["id", transition.automationId]);
          if (automation) {
            const contactAttributes = _.filter(automation.contactAttributes, function (item) {
              return item.attribute === "last_journey_update" || item.attribute === "project_id";
            });
            automation.contactAttributes = contactAttributes;
            this.saveAutomation(automation);
            const automationIndex = _.findIndex(this.automationList, [
              "id",
              this.currentNode.automationId,
            ]);
            this.automationList[automationIndex] = automation;
          }
        });
      }
    },
    addTransition() {
      this.transitions.push({
        title: "",
        condition_flag: true,
        rules: [],
      });
    },
    deleteTransition(index) {
      this.transitions.splice(index, 1);
    },
    addRules(index) {
      if (this.transitions.length === 0 && this.currentNode.type === "Start") {
        this.transitions.push({
          title: "",
          condition_flag: true,
          rules: [],
        });
      }
      this.transitions[index].rules.push({
        variable: "",
        operation: "",
        type: "",
        value: "",
      });
    },
    deleteRule(index, rule) {
      this.transitions[index].rules.splice(rule, 1);
    },
    addCalculations() {
      this.calculations.push({
        variable: "",
        type: "",
        value: "",
      });
    },
    deleteCalculations(index) {
      this.calculations.splice(index, 1);
    },
    addBeforeAction() {
      this.beforeAction.push({
        variable: "",
        value: "",
      });
    },
    addAfterAction() {
      this.afterAction.push({
        variable: "",
        value: "",
      });
    },
    addForm() {
      this.formList.push({
        id: "",
        link: "",
      });
    },
    deleteForm(index) {
      this.formList.splice(index, 1);
    },
    onSelectNode(id) {
      if (this.nodes[id].type === "End" || this.nodes[id].type === "Start") {
        this.currentNode = null;
        this.currentNodeId = "";
      } else {
        this.editingNode = true;
        this.currentNodeId = id;
        this.currentNode = _.cloneDeep(this.nodes[id]);

        if (this.currentNode.transitions && this.currentNode.transitions.length > 0) {
          for (let index = 0; index < this.currentNode.transitions.length; index++) {
            const element = this.currentNode.transitions[index];
            if (element.rules) {
              this.transitions.push = element;
            } else {
              this.transition.push({
                title: element.variable,
                condition_flag: true,
                rules: [
                  {
                    variable: element.variable,
                    operation: element.operation,
                    value: element.value,
                  },
                ],
              });
            }
          }
        } else {
          this.currentNode.transitions = [
            {
              title: "",
              condition_flag: true,
              rules: [],
            },
          ];
        }

        this.transitions = this.currentNode.transitions;
        this.calculations = this.currentNode.calculations || [];
        this.beforeAction = this.currentNode.beforeAction || [];
        this.afterAction = this.currentNode.afterAction || [];
        this.formList = this.currentNode.forms || [];
        if (this.currentNode.whatsappParams === undefined) {
          this.currentNode.whatsappParams = {
            bodyText: "",
          };
        }
        this.whatsappParams = this.currentNode.whatsappParams;

        if (this.nodes[id].type === "Message") {
          this.handleWATemplateChange();
        }
      }
    },
    onUpdateNode(node) {
      this.nodes[node.id].coordinates = node.coordinates;
      this.onSave();
    },
    onDeleteNode(id) {
      delete this.nodes[id];
      this.onSave();
    },
    onCreateLink(link) {
      this.links[link.id] = link;
      _.each(this.links, (item) => {
        if (
          item.id != link.id &&
          item.end_id == link.end_id &&
          this.nodes[link.end_id].type != "Merge" &&
          this.nodes[link.end_id].type != "End"
        ) {
          this.$refs.diagram.deleteLink(item.id);
        }

        if (
          item.id != link.id &&
          item.start_id == link.start_id &&
          item.start_port == link.start_port &&
          this.nodes[link.start_id].type === "Decision"
        ) {
          this.$refs.diagram.deleteLink(item.id);
        }
      });

      if (this.nodes[link.end_id].type === "Update" && this.nodes[link.start_id].automationId) {
        const automation = _.find(this.automationList, [
          "id",
          this.nodes[link.start_id].automationId,
        ]);

        if (automation) {
          this.nodes[link.end_id].automationId = this.nodes[link.start_id].automationId;

          automation.updates = [
            {
              failed: false,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
              property: AUTOMATION_OPERATION.ATTRIBUTES,
              updateItem: { last_journey_update: "NOW" },
              updateTo: [],
            },
          ];

          _.each(this.nodes[link.end_id].calculations, (item) => {
            if (item.variable != "last_journey_update") {
              automation.updates[0].updateItem[item.variable] = item.value;
            }
          });

          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.nodes[link.start_id].automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      } else if (
        this.nodes[link.start_id].type === "Delay" &&
        this.nodes[link.end_id].automationId
      ) {
        const automation = _.find(this.automationList, [
          "id",
          this.nodes[link.end_id].automationId,
        ]);

        if (automation) {
          this.nodes[link.start_id].automationId = this.nodes[link.end_id].automationId;
          const contactAttributes = _.filter(automation.contactAttributes, function (item) {
            return item.attribute !== "last_journey_update";
          });
          contactAttributes.push({
            attribute: "last_journey_update",
            operator: AUTOMATION_ATTRIBUTE_OPERATOR.LESS_THAN,
            comparison: "lessThan",
            value: `NOW-${this.nodes[link.start_id].delay}_${this.nodes[link.start_id].delayType}`,
          });
          contactAttributes.push({
            attribute: "last_journey_update",
            operator: AUTOMATION_ATTRIBUTE_OPERATOR.GREATER_THAN,
            comparison: "greaterThan",
            value: `NOW-${parseInt(this.nodes[link.start_id].delay) + 1}_${
              this.nodes[link.start_id].delayType
            }`,
          });

          automation.contactAttributes = contactAttributes;
          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.nodes[link.end_id].automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      } else if (
        this.nodes[link.start_id].type === "Decision" &&
        this.nodes[link.end_id].automationId
      ) {
        const automation = _.find(this.automationList, [
          "id",
          this.nodes[link.end_id].automationId,
        ]);

        if (automation) {
          const portIndex = parseInt(link.start_port.substring(7));
          const transition = this.nodes[link.start_id].transitions[portIndex];
          this.nodes[link.start_id].transitions[portIndex].automationId =
            this.nodes[link.end_id].automationId;

          const contactAttributes = _.filter(automation.contactAttributes, function (item) {
            return item.attribute === "last_journey_update" || item.attribute === "project_id";
          });
          _.each(transition.rules, (rule) => {
            const comparison = _.find(this.operationOpt, ["value", rule.operation]);
            contactAttributes.push({
              attribute: rule.variable,
              operator: rule.operation,
              comparison: comparison.comparison,
              value: rule.value,
            });
          });
          automation.contactAttributes = contactAttributes;
          this.saveAutomation(automation);
          const automationIndex = _.findIndex(this.automationList, [
            "id",
            this.nodes[link.end_id].automationId,
          ]);
          this.automationList[automationIndex] = automation;
        }
      }
      this.onSave();
    },
    onDeleteLink(id) {
      const link = this.links[id];

      if (this.nodes[link.end_id].type === "Update") {
        if (this.nodes[link.end_id].automationId) {
          const automation = _.find(this.automationList, [
            "id",
            this.nodes[link.end_id].automationId,
          ]);

          if (automation) {
            automation.updates = [
              {
                failed: false,
                method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
                property: AUTOMATION_OPERATION.ATTRIBUTES,
                updateItem: { last_journey_update: "NOW" },
                updateTo: [],
              },
            ];
            this.saveAutomation(automation);
            const automationIndex = _.findIndex(this.automationList, [
              "id",
              this.currentNode.automationId,
            ]);
            this.automationList[automationIndex] = automation;
          }

          this.nodes[link.end_id].automationId = "";
        }
      } else if (this.nodes[link.start_id].type === "Delay") {
        if (this.nodes[link.start_id].automationId) {
          const automation = _.find(this.automationList, [
            "id",
            this.nodes[link.start_id].automationId,
          ]);

          if (automation) {
            const contactAttributes = _.filter(automation.contactAttributes, function (item) {
              return item.attribute !== "last_journey_update";
            });
            automation.contactAttributes = contactAttributes;
            this.saveAutomation(automation);
            const automationIndex = _.findIndex(this.automationList, [
              "id",
              this.currentNode.automationId,
            ]);
            this.automationList[automationIndex] = automation;
          }

          this.nodes[link.start_id].automationId = "";
        }
      } else if (this.nodes[link.start_id].type === "Decision") {
        const portIndex = parseInt(link.start_port.substring(7));
        if (this.nodes[link.start_id].transitions[portIndex].automationId) {
          const automation = _.find(this.automationList, [
            "id",
            this.nodes[link.start_id].automationId,
          ]);

          if (automation) {
            const contactAttributes = _.filter(automation.contactAttributes, function (item) {
              return item.attribute === "last_journey_update" || item.attribute === "project_id";
            });
            automation.contactAttributes = contactAttributes;
            this.saveAutomation(automation);
            const automationIndex = _.findIndex(this.automationList, [
              "id",
              this.currentNode.automationId,
            ]);
            this.automationList[automationIndex] = automation;
          }

          this.nodes[link.start_id].transitions[portIndex].automationId = "";
        }
      }

      delete this.links[id];
      this.onSave();
    },
    styleNode(node) {
      const item = document.getElementById(node.id);
      if (item) {
        item.lastElementChild.style.display = "none";
        item.querySelector("svg").querySelector("rect").style.fill = this.nodeColor(node);
      }
    },
    styleAllNode() {
      document.querySelector(".diagram-editor__svg-content").style.width = "100%";
      for (var id in this.nodes) {
        const node = this.nodes[id];
        this.styleNode(node);
      }
    },
    onSave() {
      const flows = this.$refs.diagram.serialize();
      this.links = _.reduce(
        flows.links,
        function (result, value, key) {
          result[value.id] = value;
          return result;
        },
        {}
      );
      this.journey.nodes = this.nodes;
      this.journey.links = this.links;
      this.$emit("save", this.journey);
    },
    handleWATemplateChange() {
      const contentId = this.currentNode.whatsapp_template;
      const value = {};
      const item = _.find(this.whatsappTemplates, ["id", contentId]);

      value.content = item;
      value.headerText = "";
      value.bodyText = "";
      value.contentParameters = {};
      let paramaters = [];

      if (contentId && item) {
        const header = _.find(item.components, ["type", "HEADER"]);
        if (header) {
          const headerType = _.get(header, "format", "");
          let noOfHeaderParameters = 0;
          if (headerType === "TEXT") {
            value.headerText = _.get(header, "text", "");
            const headerTextMatches = _.get(header, "text", "").match(/({{\d+}})/g);
            if (Array.isArray(headerTextMatches)) {
              noOfHeaderParameters = headerTextMatches.length;
            }
            for (var i = 1; i <= noOfHeaderParameters; i++) {
              var prop = `hp${i}`;
              if (!value.contentParameters[prop]) {
                value.contentParameters[prop] = {
                  key: prop,
                  label: `Header Parameter ${i}`,
                  value: "",
                  type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                };
              }
              paramaters.push(value.contentParameters[prop]);
            }
          } else {
            if (!value.contentParameters["hm"]) {
              let paramLabel = "Header Media Link";
              if (headerType === "IMAGE") {
                paramLabel = "Header Image Link";
              } else if (headerType === "DOCUMENT") {
                paramLabel = "Header Document Link";
              } else if (headerType === "VIDEO") {
                paramLabel = "Header Video Link";
              }
              value.contentParameters["hm"] = {
                key: "hm",
                label: paramLabel,
                value: "",
                type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
              };
            }
            paramaters.push(value.contentParameters["hm"]);
          }
        }

        const body = _.find(item.components, ["type", "BODY"]);
        if (body) {
          let noOfBodyParameters = 0;
          value.bodyText = _.get(body, "text", "");
          const bodyTextMatches = _.get(body, "text", "").match(/({{\d+}})/g);
          if (Array.isArray(bodyTextMatches)) {
            noOfBodyParameters = bodyTextMatches.length;
          }
          for (var j = 1; j <= noOfBodyParameters; j++) {
            var prop1 = `p${j}`;
            if (!value.contentParameters[prop1]) {
              value.contentParameters[prop1] = {
                key: prop1,
                label: `Body Parameter ${j}`,
                value: "",
                type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
              };
            }
            paramaters.push(value.contentParameters[prop1]);
          }
        }

        const buttons = _.find(item.components, ["type", "BUTTONS"]);
        if (buttons) {
          const noOfFooterParametersTuple = _.map(buttons.buttons, (button) => {
            if (button.type !== "URL") return 0;

            const footerParameterMatches = button.url.match(/({{\d+}})/g) || [];
            return footerParameterMatches.length;
          });
          for (var ii = 1; ii <= noOfFooterParametersTuple.length; ii++) {
            const noOfParams = noOfFooterParametersTuple[ii - 1];

            if (noOfParams === 0) continue;
            for (var k = 1; k <= noOfParams; k++) {
              var prop2 = `bp${ii}${k}`;
              if (!value.contentParameters[prop2]) {
                value.contentParameters[prop2] = {
                  key: prop2,
                  label: `Button ${ii} Parameter ${k}`,
                  value: "",
                  type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                };
              }
              paramaters.push(value.contentParameters[prop2]);
            }
          }
        }
      }

      value.allParameters = paramaters;
      this.whatsappParams = value;
    },
    newAutomation(automationId) {
      const automation = {
        id: automationId,
        name: "",
        context: "",
        channels: [],
        messages: [],
        contactTags: [],
        contactStages: [],
        contactList: [],
        contactAttributes: [],
        contactOperations: [],
        stateVariables: [],
        scheduleType: AUTOMATION_SCHEDULE_TYPE.NOT_SET,
        scheduleCron: [],
        startDate: null,
        endDate: null,
        sentTime: null,
        updates: [],
      };

      automation.channels = [
        {
          name: AUTOMATION_CHANNEL_NAME.WHATSAPP,
          settings: {
            endpoint: this.journey.settings?.wa_endpoint,
          },
        },
      ];
      automation.contactAttributes = [
        {
          attribute: "project_id",
          operator: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
          comparison: "is",
          value: this.journey.id,
        },
      ];
      automation.contactOperations = [AUTOMATION_OPERATION.ATTRIBUTES];
      automation.updates = [
        {
          failed: false,
          method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
          property: AUTOMATION_OPERATION.ATTRIBUTES,
          updateItem: { last_journey_update: "NOW" },
          updateTo: [],
        },
      ];
      automation.startDate = moment().tz(this.clientTimezone);

      return automation;
    },
    async saveAutomation(automation) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($automation: JSON!) {
              outreachAutomationAPI {
                upsert(automation: $automation) {
                  id
                  name
                  channels
                  messages
                  updates
                  startDate
                  endDate
                }
              }
            }
          `,
          variables: {
            automation,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
  },
};
</script>

<style>
.journey-card .el-card__body {
  padding: 20px 0 20px 20px;
}
</style>
<style scoped>
.journey-label {
  font-weight: bold;
}
</style>
