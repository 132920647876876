<template>
  <div>
    <el-form-item label-position="horizontal" label="Content-Type:">
      <el-select
        :value="contentType"
        @input="updateContentType($event)"
        placeholder="Select content type"
        class="full-width-input"
      >
        <el-option :label="BODY_TYPE_JSON" :value="BODY_TYPE_JSON"></el-option>
        <el-option :label="BODY_TYPE_FORM" :value="BODY_TYPE_FORM"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if="!contentType || contentType === BODY_TYPE_JSON">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="Input body"
        :value="body"
        @input="$emit('update:body', $event)"
      ></el-input>
    </el-form-item>

    <el-form-item v-if="contentType === BODY_TYPE_FORM">
      <form-url-encoded-input :body.sync="localBody" />
    </el-form-item>
  </div>
</template>

<script>
import FormUrlEncodedInput from "./BodyInput/FormUrlEncodedInput";

const BODY_TYPE_JSON = "application/json";
const BODY_TYPE_FORM = "application/x-www-form-urlencoded";

export default {
  components: {
    FormUrlEncodedInput,
  },
  props: ["headers", "body"],
  data() {
    return {
      BODY_TYPE_JSON,
      BODY_TYPE_FORM,
      localBody: _.cloneDeep(this.body),
    };
  },
  computed: {
    contentType() {
      return (
        _.find(this.headers, (header) => header.key.toLowerCase() === "content-type")?.value ||
        BODY_TYPE_JSON
      );
    },
  },
  methods: {
    updateContentType(value) {
      const updatedHeaders = _.cloneDeep(this.headers);
      const contentTypeHeader = _.find(
        updatedHeaders,
        (header) => header?.key?.toLowerCase() === "content-type"
      );
      if (contentTypeHeader) {
        contentTypeHeader.value = value;
      } else {
        updatedHeaders.push({ key: "Content-Type", value: value });
      }
      this.$emit("update:headers", updatedHeaders);
    },
  },
  watch: {
    localBody(newBody) {
      this.$emit("update:body", newBody);
    },
    body(newBody) {
      this.localBody = _.cloneDeep(newBody);
    },
  },
};
</script>

<style scoped>
.full-width-input {
  width: 100%;
}
</style>
