import { render, staticRenderFns } from "./QueueChatsTab.vue?vue&type=template&id=549b5817&scoped=true&"
import script from "./QueueChatsTab.vue?vue&type=script&lang=js&"
export * from "./QueueChatsTab.vue?vue&type=script&lang=js&"
import style0 from "./QueueChatsTab.vue?vue&type=style&index=0&id=549b5817&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549b5817",
  null
  
)

export default component.exports